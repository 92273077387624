export const cube_obj = {
  name:"cube",
  vertices:[
    {
      position:{
        x:-0.5,
        y:-0.5,
        z:-0.5
      },
      normal:{
        x:0,
        y:-1,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:1.0,
        g:0.0,
        b:0.0
      }
    },
    {
      position:{
        x:0.5,
        y:-0.5,
        z:-0.5
      },
      normal:{
        x:0,
        y:-1,
        z:0
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:1.0,
        g:1.0,
        b:1.0
      }
    },
    {
      position:{
        x:-0.5,
        y:-0.5,
        z:0.5
      },
      normal:{
        x:0,
        y:-1,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:0.5,
        y:-0.5,
        z:0.5
      },
      normal:{
        x:0,
        y:-1,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },

    {
      position:{
        x:-0.5,
        y:-0.5,
        z:-0.5
      },
      normal:{
        x:0,
        y:0,
        z:-1
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:0.5,
        y:-0.5,
        z:-0.5
      },
      normal:{
        x:0,
        y:0,
        z:-1
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:0.5,
        y:0.5,
        z:-0.5
      },
      normal:{
        x:0,
        y:0,
        z:-1
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:-0.5,
        y:0.5,
        z:-0.5
      },
      normal:{
        x:0,
        y:0,
        z:-1
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },

    {
      position:{
        x:0.5,
        y:-0.5,
        z:-0.5
      },
      normal:{
        x:1,
        y:0,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:0.5,
        y:0.5,
        z:-0.5
      },
      normal:{
        x:1,
        y:0,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:0.5,
        y:0.5,
        z:0.5
      },
      normal:{
        x:1,
        y:0,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:0.5,
        y:-0.5,
        z:0.5
      },
      normal:{
        x:1,
        y:0,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },

    {
      position:{
        x:0.5,
        y:0.5,
        z:-0.5
      },
      normal:{
        x:0,
        y:1,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:-0.5,
        y:0.5,
        z:-0.5
      },
      normal:{
        x:0,
        y:1,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:-0.5,
        y:0.5,
        z:0.5
      },
      normal:{
        x:0,
        y:1,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:0.5,
        y:0.5,
        z:0.5
      },
      normal:{
        x:0,
        y:1,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },

    {
      position:{
        x:0.5,
        y:0.5,
        z:0.5
      },
      normal:{
        x:0,
        y:0,
        z:1,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:-0.5,
        y:0.5,
        z:0.5
      },
      normal:{
        x:0,
        y:0,
        z:1,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:-0.5,
        y:-0.5,
        z:0.5
      },
      normal:{
        x:0,
        y:0,
        z:1,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:0.5,
        y:-0.5,
        z:0.5
      },
      normal:{
        x:0,
        y:0,
        z:1,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },

    {
      position:{
        x:-0.5,
        y:0.5,
        z:-0.5
      },
      normal:{
        x:-1,
        y:0,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:-0.5,
        y:-0.5,
        z:-0.5
      },
      normal:{
        x:-1,
        y:0,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:-0.5,
        y:0.5,
        z:0.5
      },
      normal:{
        x:-1,
        y:0,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },
    {
      position:{
        x:-0.5,
        y:-0.5,
        z:0.5
      },
      normal:{
        x:-1,
        y:0,
        z:0,
      },
      texCoord:{
        x:0,
        y:0
      },
      color:{
        r:0.0,
        g:0.0,
        b:1
      }
    },




  ],
  type:"TRIANGLES",
  indeces:[0,2,3,0,3,1,
           4,5,7,5,6,7,
           8,11,10,8,10,9,
           12,15,14,12,14,13,
           19,18,17,19,17,16,
           20,22,23,20,23,21]
}
